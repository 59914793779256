




























































import { Component, Vue, Prop } from 'vue-property-decorator'

import DeletePictureModal from '@/partials/modals/ProfilePicture/delete.vue'

@Component({
  components: {
    DeletePictureModal
  }
})
export default class ProfileUploader extends Vue {
  @Prop() imgUrl !: string
  @Prop() pictureId !: string
  @Prop() ownerId !: string
  @Prop() type!: ProfileUploaderType
  @Prop({ type: Boolean }) isSquare!: boolean
  @Prop({ type: Boolean }) noDelete!: boolean
  myImgUrl = ''
  imgSrc = ''
  isEditing = false
  componentKey = 0
  deleteModalIsOpened = false

  getResult () {
    this.processImage()

    this.isEditing = false

    this.$emit('image-loaded', this.imgSrc)
  }

  processImage () {
    const clipper = this.$refs.clipper as any
    const canvas = clipper.clip()

    this.imgSrc = canvas.toDataURL()
  }

  deletedPicture () {
    this.deleteModalIsOpened = false
    this.myImgUrl = ''
    this.imgSrc = ''
    this.isEditing = false
    this.componentKey++

    this.$emit('deleted')
  }
}
