



































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver,
    ValidationProvider
  }
})
export default class DefaultPassword extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true }) userId !: string
  @Prop({ required: true }) visible !: boolean

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  closeModal () {
    this.resetForm()
    this.$emit('close')
  }

  submit () {
    axios.patch(`/user/${this.userId}/password/default`, snakeCaseKeys(this.form, { deep: true }))
      .then((response) => {
        this.$emit('submitted')
        const flashMessage = {
          message: response.data.data,
          isSuccess: true
        }

        this.setFlashMessage(flashMessage)
        this.resetForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetForm () {
    this.form = {
      confirm: '',
      password: '',
      passwordConfirmation: '',
      errors: {
        confirm: '',
        password: '',
        passwordConfirmation: ''
      }
    }
  }
}
