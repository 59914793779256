





























import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class ProfilePictureDelete extends Vue {
  @Prop() pictureId !: string
  @Prop({ required: true }) visible !: boolean
  @Prop({ required: true }) ownerId !: string
  @Prop({ required: true }) type!: ProfileUploaderType

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  closeModal () {
    this.$emit('close')
  }

  submit () {
    let url = `/${this.type}/${this.ownerId}/picture/${this.pictureId}`

    if (this.type === 'event-activity') {
      url = `/event/${this.$route.params.eventId}/activity/${this.$route.params.activityId}/picture/${this.pictureId}`
    }
    axios.delete(url, { data: this.form })
      .then(() => {
        this.$emit('submitted')
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
