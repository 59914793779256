













































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'
import { namespace } from 'vuex-class'

import NotificationMixin from '@/mixins/notificationMixin'
import UseTerms from '@/partials/UseTerms.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ChangePasswordModal from '@/partials/modals/Profile/ChangePassword.vue'
import DefaultPasswordModal from '@/partials/modals/Profile/DefaultPassword.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

const sessionModule = namespace('session')
@Component({
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    UseTerms,
    DateInput,
    NotifyOnFailed,
    ProfileUploader,
    PasswordConfirmField,
    ChangePasswordModal,
    DefaultPasswordModal
  }
})
export default class UserProfileForm extends Mixins(NotificationMixin, ValidatorConfigMixin) {
  @Prop({ default: 'Usuário' }) title!: string
  @Prop() userId!: string
  @Prop({ type: Boolean, default: false }) profile!: boolean
  @Prop({ type: String, default: '' }) inviteToken!: string
  invalidToken = false
  changePasswordModalIsOpened = false
  defaultPasswordModalIsOpened = false

  @sessionModule.Action('login') loginAction!: Function
  @sessionModule.Getter isNewSelfSubscriber!: boolean
  @sessionModule.Getter getSubscription!: Record<string, string>
  @sessionModule.Getter state!: Record<string, string>

  roles: Roles = []
  ufs = []
  treatments = ['Dr.', 'Dra.', 'Sr.', 'Sra.']
  done = true

  userRestoreEmail = ''

  form: Record<string, any> = {
    id: '',
    name: '',
    email: '',
    sex: 'm',
    birthday: '2000-01-01 12:00:00',
    roles: [],
    password: '',
    associate: 'false',
    termsAccepted: true,
    picture: '',
    sendWelcomeMail: true,
    confirm: '',
    profile: {
      treatment: 'Dr.',
      cpf: '',
      rg: '',
      crm: '',
      mobile: '',
      uf: 'DF',
      teotNumber: ''
    },
    errors: {
      name: '',
      email: '',
      sex: '',
      birthday: '',
      roles: '',
      password: '',
      'profile.cpf': '',
      'profile.mobile': '',
      'profile.rg': '',
      'profile.crm': '',
      'profile.teot_number': '',
      sendWelcomeMail: '',
      confirm: ''
    }
  }

  profilePictureURL = ''
  pictureId = ''

  selectedRoles: Roles = []

  @Watch('form.roles', { immediate: true })
  onRoleChange (value: Array<string>) {
    this.selectedRoles = this.roles.filter((role: Role) => value.includes(role.id))

    if (this.selectedRoles.find((role) => role.name === 'Usuário')) {
      this.form.associate = 'false'
    } else {
      this.form.associate = 'true'
    }
  }

  diselectOtherRoles (roleId: string) {
    const hasBeenSelected = !!this.form.roles.find((role: string) => role === roleId)

    if (hasBeenSelected) {
      const selectedRole = this.roles.find(role => role.id === roleId)

      if (selectedRole?.name === 'Usuário') {
        this.form.roles = [roleId]
      } else {
        const userRole = this.roles.find(role => role.name === 'Usuário')
        this.form.roles = this.form.roles.filter((role: string) => role !== userRole?.id)
      }
    }
  }

  created () {
    if (!this.profile && !this.inviteToken) {
      axios.get('roles/user')
        .then((response) => {
          this.roles = response.data.data
          if (process.env.NODE_ENV === 'development') {
            this.fillFormWithDefaults()
          }
        })
    }

    axios.get('geo/br/states')
      .then((response) => {
        this.ufs = response.data.data
      })

    if (this.userId) {
      axios.get(`user/${this.userId}/profile`)
        .then((response) => {
          const profile = camelCaseKeys(response.data.data, { deep: true })

          this.form.name = profile.name
          this.form.email = profile.email
          this.form.sex = profile.sex
          this.form.id = profile.id
          this.form.birthday = moment(profile.birthday).toISOString()
          this.form.roles = profile.roles.map((role: Role) => role.id)

          profile.profile.forEach((item: any) => {
            if (item.metaKey) {
              this.form.profile[item.metaKey] = item.metaValue
            }
          })

          this.form.profile = camelCaseKeys(this.form.profile)
          if (profile.picture) {
            this.profilePictureURL = profile.picture.publicPath
            this.pictureId = profile.picture.id
          }
        }).catch(error => {
          console.log(error)
        })
    } else if (this.inviteToken !== '') {
      axios.get(`user/invite/${this.inviteToken}`)
        .then((response) => {
          const profile = response.data.data
          this.form.name = profile.name
          this.form.email = profile.email
          this.form.sex = profile.sex
          this.form.id = profile.id
          this.form.birthday = moment(profile.birthday).toISOString()
          this.form.roles = profile.roles.map((role: Role) => role.id)

          profile.profile.forEach((item: any) => {
            this.form.profile[item.meta_key] = item.meta_value
          })
        }).catch((err) => {
          this.invalidToken = true
          console.log(err)

          this.setNotification('error', 'O token não existe ou esta expirado.')
        })
    }

    this.form.sendWelcomeMail = !this.profile && !this.inviteToken && !this.userId
  }

  fillFormWithDefaults () {
    const userRole = this.roles.find((role: Role) => role.name === 'Usuário')

    Object.assign(this.form, {
      name: 'Nome do Usuário',
      email: 'email@teste.com',
      sex: 'm',
      birthday: '2000-01-01 12:00:00',
      roles: [userRole?.id],
      password: '',
      profile: {
        treatment: 'Dr.',
        cpf: '000.000.000-00',
        mobile: '(00) 00000-0000',
        uf: 'DF',
        teotNumber: '0000',
        rg: '0000000',
        crm: '000000'
      }
    })
  }

  submitForm () {
    this.done = false

    const verb = this.userId || this.inviteToken ? 'put' : 'post'
    let route = 'users'
    let successVerb = 'cadastrado'

    if (this.userId) {
      route = `user/${this.userId}`
      successVerb = 'editado'
      if (this.profile) {
        route = `user/${this.userId}/profile`
      }
    }

    if (this.inviteToken !== '') {
      route = `user/invite/${this.inviteToken}`
      successVerb = 'editado'
    }

    const requestForm = snakeCaseKeys(Object.assign({}, this.form), { deep: true })
    requestForm.profile = []

    if (verb === 'post') requestForm.associate = this.form.associate !== 'false'

    if (this.inviteToken === '') {
      delete requestForm.terms_accepted
    }

    delete requestForm.errors

    Object.entries(this.form.profile).forEach(([key, value]) => {
      if (value) {
        if (key !== 'voucherId' && key !== 'eventId') {
          requestForm.profile.push({ key: key, value: value })
        }
      }
    })

    axios[verb](route, requestForm)
      .then((response) => {
        this.setNotification('success', `O usuário ${this.form.name} foi ${successVerb}`)
        if (route === `user/invite/${this.inviteToken}`) this.invalidToken = true
        if (this.form.profile.voucherId && this.form.profile.eventId) {
          this.autoLogin()
        }
        this.$emit('submitted', response.data.data)
      })
      .catch(err => {
        const response = err.response
        const errors = response.data.errors
        if (errors) {
          this.setNotification('error', 'Os dados fornecidos não são válidos, verifique o formulário.')
          if (response.data.errors) {
            const errors = response.data.errors

            Object.entries(errors).map(([key, value]: [string, any]) => {
              this.form.errors[key] = value[0]
            })

            if (this.form.errors.email === 'RestorableUser') {
              this.userRestoreEmail = this.form.email
            }
          }
        }
      })
      .finally(() => { this.done = true })
  }

  private autoLogin () {
    const loginForm = {
      email: this.form.email,
      password: this.form.password,
      voucherId: this.form.profile.voucherId
    }
    this.loginAction(snakeCaseKeys(loginForm, { deep: true }))
      .then(() => {
        if (this.state.success) {
          const redirect = this.$route.query.redirect as string

          if (redirect !== undefined) {
            this.$router.push(redirect)
          } else {
            const { eventId, subscriptionId } = this.getSubscription
            if (this.isNewSelfSubscriber) {
              this.$router.push({
                name: 'EventVoucherFinishAndPrint',
                params: {
                  eventId,
                  subscriptionId
                }
              })
            } else {
              this.$router.push({ name: 'Home' })
            }
          }
        }
      })
  }

  restoreUser () {
    axios.patch('/user/restore', { email: this.userRestoreEmail })
      .then((response) => {
        const userId = response.data.data.id
        this.$router.push({ name: 'UsersEdit', params: { id: userId } })
      })
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      email: '',
      sex: '',
      birthday: '',
      roles: '',
      password: '',
      'profile.cpf': '',
      'profile.mobile': ''
    }
  }
}
